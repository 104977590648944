import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import * as yup from "yup";
import { Container, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import Validation, { useZipCodeValidation } from "utility/validation";
import { scrollToTop, modalScrollToTop } from '../../../../utility/functions';

// Import SVG Icons.
import { LHReadyToHire, LHPlanning } from "components/atoms/Icons";

import { FormContextLHSSMultiStepEstimate } from "./LHSSMultiStepEstimate";
import styled from "@emotion/styled";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import TermAndCondition from "components/atoms/TermAndCondition";
import LeafBox from "components/atoms/LeafBox";
import PostService from "utility/postService";
import { LeafButton } from "components/atoms/LeafButtons";
import { useProduct, useComponentName } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";

const InitialStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const zipSource = props.dataSet?.zipsource || "localzip";
  let zipCode = '';
  let zipcodeInputValue = '';
  let serviceAreaFlag = false;
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  let zipCodeValidationStatus = 'invalid';
  const formRef = useRef(null);

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();

  const { setActiveLHSSStepIndex, formData, setFormData } = useContext(FormContextLHSSMultiStepEstimate);
  const ValidationSchema = yup.object().shape({
    zipCode: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`)
      .test('available-zipcode', zipCodeValidation.restrictedValidationMessage, async function (zipcode) {
        if (!(formData.zipCode) && (zipcodeInputValue !== zipcode) && (zipCodeValidation.validationPattern.test(zipcode))) {
          serviceAreaFlag = await PostService.checkAvailableService(zipcode, product, zipSource);
          zipcodeInputValue = zipcode;
          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if (!serviceAreaFlag) {
            DataLayerService.formFailEvent(form, location, {'zipCode': ['OOA restriction for ' + zipcode + ' failed.']});
          }
          if (userJourneyTracking) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcodeInputValue, 'completed', zipCodeValidationStatus);
          }
          // Return true if the validation passes, false if it fails
          return serviceAreaFlag;
        }
        else {
          if (formData.zipCode) {
            return true;
          }

          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if ((userJourneyTracking) && formData.zipCode !== undefined) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcode, 'completed', zipCodeValidationStatus);
          }
          return serviceAreaFlag;
        }
      })
  });

  const buttonSubmit = (e) => {

    // Validation Error.
    const zipCodevalidationError = Validation.ZipValidationCodeDataLayerValidation(zipCode, zipCodeValidation.validationPattern);

    if ((Object.keys(zipCodevalidationError).length !== 0) && (componentName !== 'LeafHomeZipChecker')) {
      DataLayerService.formFailEvent(form, location, zipCodevalidationError);

      modalScrollToTop(props.modalComponent);
    }

    // Scroll to the Top.
    scrollToTop(formRef);
  }

  const handleOnBlur = (event) => {
    zipCode = event.target.value;
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleProjectStatusClick = (value, setFieldValue) => {
    setFieldValue("projectStatus", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: 552,
    width: "100%",
    height: "auto",
  }));

  return (
    <Formik
      initialValues={{
        zipCode: formData.zipCode
          ? formData.zipCode
          : localStorage.getItem("zipCode") || '',
        product: "",
        projectStatus: "",
      }}
      validationSchema={ValidationSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={async (values) => {
        const cookies = await PostService.getUTMAttributes();
        const additionalInformation = {
          utm_campaign: cookies.utm_campaign ? cookies.utm_campaign.toUpperCase() : '',
        };
        values.product = ['Stair Lifts'];
        const data = { ...formData, ...values, ...additionalInformation };
        setFormData(data);

        // Passing lf.form.assessment event to DataLayer.
        DataLayerService.formAssessmentEvent(data);

        setActiveLHSSStepIndex('group');

        // Scroll to the Top.
        scrollToTop(formRef);

        if (userJourneyTracking) {
          let stepNumber = 1;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 2;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting, errors }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              ref={formRef}
              className="flex flex-col justify-center items-start step1"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
              <h3 className="fw-normal mb-3">
                Your free, no-obligation estimate is good for an entire year.
              </h3>
              <ZipCodeInput
                fieldName={'zipCode'}
                style={{
                  display: formData.zipCode ? "none" : "inline"
                }}
                label={formData.zipCode ? false : true}
              />

              <LeafBox>
              <label className="small mb-3">What is the status of your project?</label>
                <div>
                  <Field name="projectStatus">
                    {() => (
                      <LeafBox style={{
                        display: "flex",
                        alignItems: "start"
                      }}>
                        <LHReadyToHire handleClick={() =>
                          handleProjectStatusClick(1, setFieldValue)
                        } />

                        <LHPlanning handleClick={() =>
                          handleProjectStatusClick(2, setFieldValue)
                        } />
                      </LeafBox>
                    )}
                  </Field>
                </div>
              </LeafBox>
              <Divider className="divider" />
                <LeafButton
                  buttonClass='rounded-3 btn-primary w-100 fw-medium'
                  type="submit"
                  handleClick={buttonSubmit}
                  isSubmitting={isSubmitting}
                >
                  Next
                </LeafButton>
              <ProgressBar customClass="" />
              <TermAndCondition />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default InitialStep;
