import React, { useContext, useState, createContext, useEffect } from "react";
import { FormContext } from "../../../../App";
import PostService from "utility/postService";
import InitialStep from './InitialStep';
import CustomerGroupStep from './CustomerGroupStep';
import CustomerDetailsStep from './CustomerDetailsStep';
import ProjectDetailsStep from './ProjectDetailsStep';
import FinalStep from './FinalStep';
import ThankYou from './thankyou';
import "../global-style.scss";
import TrustedFormScript from "components/atoms/TrustedFormScript";

export const FormContextLHSSMultiStepEstimate = createContext({
  activeLHSSStepIndex: ''
});

const LHSSMultiStepEstimate = (props) => {
  const [ activeLHSSStepIndex, setActiveLHSSStepIndex ] = useState('');
  const [formData, setFormData] = useState({ zipCode: props.zipCode });
  const { setVLeafhome } = useContext(FormContext);
  const leahomeVersion = props.dataSet?.vleafhome ? props.dataSet?.vleafhome : true;
  useEffect(() => {
    setVLeafhome(leahomeVersion);
  });

  let marketoFlag = false;
  let marketoData = {}

  if (marketoFlag === undefined || marketoFlag === null || marketoFlag === false) {
    const loadData = PostService.loadDataMarketo();
    loadData.then((data) => {
      marketoData = data
    });
    marketoFlag = true;
  }

  let stepContent;
  switch (activeLHSSStepIndex) {
    case 'group':
      stepContent = <CustomerGroupStep marketoData={ marketoData } {...props} />;
      break;
    case 'details':
      stepContent = <CustomerDetailsStep marketoData={ marketoData } {...props} />;
      break;
    case 'project':
      stepContent = <ProjectDetailsStep marketoData={ marketoData } {...props} />;
      break;
    case 'final':
      stepContent = <FinalStep marketoData={ marketoData } {...props} />;
      break;
    case 'ThankYou':
      stepContent = <ThankYou {...props} />;
      break;
    default:
      stepContent = <InitialStep marketoData={ marketoData } {...props} />;
      break;
  }

  return (
    <FormContextLHSSMultiStepEstimate.Provider
      value={{activeLHSSStepIndex, setActiveLHSSStepIndex, formData, setFormData }}
    >
      <div className="leafhome-forms leaf-forms">{stepContent}</div>
      <TrustedFormScript />
    </FormContextLHSSMultiStepEstimate.Provider>
  );
}

export default LHSSMultiStepEstimate;
