/*
 * This Service has different type of validation
 * rules for different type of fields.
*/
import { useProduct, useVersionLeafhome, useZipcodePlaceholder } from 'hooks';
import ReactRecaptcha3 from 'react-google-recaptcha3';
import { getBaseUrl } from './functions';

export const useZipCodeValidation = () => {
  let zipCode = {};
  const product = useProduct();
  const vLeafhome = useVersionLeafhome();
  const zipCodePlaceholder = useZipcodePlaceholder();
  switch (product) {
    case 'ca':
      zipCode = {
        zipCodeText: 'Postal Code',
        zipCodePlaceHolder: zipCodePlaceholder || 'E.g., H0H 0H0',
        validationPattern: /^(?!.*[dfioquDFIOQU])[a-vxyA-VXY][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$/,
        validationMessage: 'Postal Code is not valid.',
        restrictedValidationMessage: `We're sorry, ${vLeafhome ? 'Leaf Home' : 'LeafFilter'} is not available in your area, however, we are expanding rapidly and can notify you when ${vLeafhome ? 'Leaf Home' : 'LeafFilter'} or other Leaf Home services are available.`,
      };
      break;

    default:
      let restrictedValidationMessage = '';
      switch (product) {
        case 'lhss':
          restrictedValidationMessage = `We're sorry, ${vLeafhome ? 'Leaf Home' : 'Leaf Home Safety Solution'} is not available in your area, however, we are expanding rapidly and can notify you when ${vLeafhome ? 'Leaf Home' : 'Leaf Home Safety Solution'} or other Leaf Home services are available.`;
          break;

        case 'lhws':
          restrictedValidationMessage = `We're sorry, ${vLeafhome ? 'Leaf Home' : 'Leaf Home Water Solution'} is not available in your area, however, we are expanding rapidly and can notify you when ${vLeafhome ? 'Leaf Home' : 'Leaf Home Water Solution'} or other Leaf Home services are available.`;
          break;

        default:
          restrictedValidationMessage = `We're sorry, ${vLeafhome ? 'Leaf Home' : 'LeafFilter'} is not available in your area, however, we are expanding rapidly and can notify you when ${vLeafhome ? 'Leaf Home' : 'LeafFilter'} or other Leaf Home services are available.`;
          break;
      }

      zipCode = {
        zipCodeText: 'Zip Code',
        zipCodePlaceHolder: zipCodePlaceholder || 'E.g., 10001',
        validationPattern: /^[0-9]{5}(-[0-9]{4})?$/,
        validationMessage: 'ZIP Code must be a 5-digit number.',
        restrictedValidationMessage: restrictedValidationMessage,
      };
      break;
  }

  return zipCode;
}

class Validation {
  constructor() {
    this.baseUrl = getBaseUrl();
  }

  /*
   * Validation for Full Name.
  */
  FullNameDataLayerValidation(fullName) {
    let NameValidation = {};

    // Validation for Full name.
    if (fullName === '') {
      NameValidation = {'fullName': ['required']};
    }
    else {
      let names = fullName.trim().split(' ');
      if (names.length < 2) {
        NameValidation = {'fullName': ['pattern']};
      }
    }

    return NameValidation;
  }

  /*
   * Validation for Email Address.
  */
  EmailDataLayerValidation(emailAddress) {
    let EmailAddressValidation = {};

    // Validation for Email Address.
    if (emailAddress === '') {
      EmailAddressValidation = {'emailAddress': ['required']};
    }
    else {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(emailAddress)) {
        EmailAddressValidation = {'emailAddress': ['type']};
      }
    }

    return EmailAddressValidation;
  }

  /*
   * Validation for Zip code.
  */
  ZipValidationCodeDataLayerValidation(zipCode, pattern) {
    let ZipCodeValidation = {};

    // Validation for Zip Code.
    if (zipCode === '') {
      ZipCodeValidation = {'zipCode': ['required']};
    }
    else if (!pattern.test(zipCode)) {
      ZipCodeValidation = {'zipCode': ['pattern']};
    }

    return ZipCodeValidation;
  }

  /*
   * Validation for Phone Number.
  */
  PhoneDataLayerValidation(phoneNumber) {
    let phoneNumberValidation = {};

    // Validation for Phone Number.
    if (phoneNumber === '') {
      phoneNumberValidation = {'phoneNumber': ['required']};
    }
    else {
      const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
      if (!phoneRegex.test(phoneNumber)) {
        phoneNumberValidation = {'phoneNumber': ['pattern']};
      }
    }

    return phoneNumberValidation;
  }

  /*
   * Recaptcha Verification.
  */
  async RecaptchaVerification() {
    const token = await ReactRecaptcha3.getToken().then(
      (recaptchaToken) => {
        return recaptchaToken;
      },
      (error) => {
        console.log("Error in sending data");
        return error;
      }
    );

    // Send a POST request to your server with the token and verify it there.
    try {
      const response = await fetch(`${this.baseUrl}/api/verify-recaptcha-v3`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({token}),
      });

      if (response.ok) {
        const data = await response.json();
        return {
          'token': token,
          'score': data.score,
        };
      } else {
        // Handle server errors or network issues.
        console.error("Server error:", response.statusText);
      }
    } catch (error) {
      // Handle unexpected errors.
      console.error("Error:", error.message);
    }
  }

  /**
   * Validate Terms of Service
   * @param {*} value
   * @returns
   */
  ValidateTermsofService(value) {
    let error;
    if (value === false || value === null || value === undefined) {
      error = 'The terms and conditions must be accepted.';
    }
    return error;
  }

  /*
   * Validation for Terms of Service.
  */
  TermsOfServiceDataLayerValidation(emailOptIn, country) {
    let emailOptInValidation = {};
    if (country !== 'ca') {
      return emailOptInValidation;
    }

    // Validation for Terms of Service.
    if (emailOptIn === false) {
      emailOptInValidation = {'emailOptIn': ['required']};
    }

    return emailOptInValidation;
  }

  /*
   * Validation for First  Name.
  */
  FirstNameDataLayerValidation(firstName) {
    let NameValidation = {};

    // Validation for Full name.
    if (firstName === '') {
      NameValidation = {'firstName': ['required']};
    }
    return NameValidation;
  }

  /*
   * Validation for Last  Name.
  */
  LastNameDataLayerValidation(lastName) {
    let NameValidation = {};

    // Validation for Full name.
    if (lastName === '') {
      NameValidation = {'lastName': ['required']};
    }
    return NameValidation;
  }

}

const validation = new Validation();
export default validation;
