import React, { useContext, createContext, useEffect } from "react";
import { FormContext } from "../../../../App";
import PostService from "utility/postService";
import Step1 from '../MultiStep-Estimate/Step1';
import Step2 from '../MultiStep-Estimate/Step2';
import Step3 from '../MultiStep-Estimate/Step3';
import Step4 from '../MultiStep-Estimate/Step4';
import TrustedFormScript from "components/atoms/TrustedFormScript";
import ThankYou from './ThankYou';
import ThankYouB from './ThankYouB';
import ThankYouC from './ThankYouC';
export const FormContextSelfSchedulerStepper = createContext();

const SelfScheduler = (props) => {
  const { activeStepIndex, setVLeafhome } = useContext(FormContext);
  const thankYouSection = props.dataSet?.thankyoumessage ? props.dataSet.thankyoumessage : '';
  let marketoFlag = false;
  let marketoData = {}
  const leahomeVersion = props.dataSet?.vleafhome ? props.dataSet?.vleafhome : true;
  useEffect(() => {
    setVLeafhome(leahomeVersion);
  });

  if ((marketoFlag === undefined ||
    marketoFlag === null ||
    marketoFlag === false)) {
    try {
      const loadData = PostService.loadDataMarketo();
      (loadData) && loadData.then((data) => {
        marketoData = data
      });
      marketoFlag = true;
    }
    catch (error) {
      console.log(error);
    }
  }

  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <Step1 marketoData={ marketoData } {...props} />;
      break;

    case 1:
      stepContent = <Step2  marketoData={ marketoData } {...props} />;
      break;

    case 2:
      stepContent = <Step3 marketoData={ marketoData } {...props} />;
      break;

    case 3:
      stepContent = <Step4 marketoData={ marketoData } {...props} />;
    break;

    case 4:
      switch (thankYouSection) {
        case 'test-B':
          stepContent = <ThankYouB {...props} />;
          break;

        case 'test-C':
          stepContent = <ThankYouC {...props} />;
          break;

        default:
          stepContent = <ThankYou {...props} />;
        break;
      }
    break;

    default:
      break;
  }

  return (
    <FormContextSelfSchedulerStepper.Provider value={{}}>
      <div className="leafhome-forms leaf-forms">{stepContent}</div>
      <TrustedFormScript />
    </FormContextSelfSchedulerStepper.Provider>
  );
}

export default SelfScheduler;
