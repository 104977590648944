import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { FormContext } from "../../../../App";
import * as yup from "yup";
import { Container, Paper, FormControl, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import TermAndCondition from "components/atoms/TermAndCondition";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import "./style.scss";
import "../global-style.scss";
import DataLayerService from "utility/dataLayerService";
import Validation, { useZipCodeValidation } from "utility/validation";
import PostService from "utility/postService";
import LeafBox from "components/atoms/LeafBox";
import { LeafButton } from "components/atoms/LeafButtons";
import { useProduct, useComponentName } from "hooks";
import { scrollToTop, modalScrollToTop } from '../../../../utility/functions';

// Import SVG Icons.
import { LHOneStoryIcon, LHTwoStoryIcon, LHThreeStoryIcon, LHSmallIcon, LHMediumIcon, LHLargeIcon } from "components/atoms/Icons";
import ProgressBar from "components/atoms/ProgressBar";

const Step1 = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const zipSource = props.dataSet?.zipsource || "localzip";
  const userJourneyTracking = props.dataSet?.tracking || "";
  const componentName = props.component || "LeafHomeZipChecker";
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const formRef = useRef(null);

  let zipCode = '';
  let zipcodeInputValue = '';
  let serviceAreaFlag = false;
  let zipCodeValidationStatus = 'invalid';
  const product = useProduct();

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();

  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  const ValidationSchema = yup.object().shape({
    zipCode: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`)
      .test('available-zipcode', zipCodeValidation.restrictedValidationMessage, async function (zipcode) {
        if (!(formData.zipCode) && (zipcodeInputValue !== zipcode) && (zipCodeValidation.validationPattern.test(zipcode))) {
          serviceAreaFlag = await PostService.checkAvailableService(zipcode, product, zipSource);
          zipcodeInputValue = zipcode;
          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if (!serviceAreaFlag) {
            DataLayerService.formFailEvent(form, location, {'zipCode': ['OOA restriction for ' + zipcode + ' failed.']});
          }
          if (userJourneyTracking) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcodeInputValue, 'completed', zipCodeValidationStatus);
          }
          // Return true if the validation passes, false if it fails
          return serviceAreaFlag;
        }
        else {
          if (formData.zipCode) {
            return true;
          }

          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if ((userJourneyTracking) && formData.zipCode !== null && !(localStorage.getItem("zipCode"))) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipCode, 'completed', zipCodeValidationStatus);
          }
          return serviceAreaFlag;
        }
      }),
  });

  const buttonSubmit = (e) => {

    // Validation Error.
    const zipCodevalidationError = Validation.ZipValidationCodeDataLayerValidation(zipCode, zipCodeValidation.validationPattern);

    if ((Object.keys(zipCodevalidationError).length !== 0) && (componentName !== 'LeafHomeZipChecker')) {
      DataLayerService.formFailEvent(form, location, zipCodevalidationError);

      modalScrollToTop(props.modalComponent);
    }

    // Scroll to the Top.
    scrollToTop(formRef);
  }

  const handleOnBlur = (event) => {
    switch (event.target.name) {
      case 'zipCode':
        zipCode = event.target.value;
        break;
      default:
        break;
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleHomeSizeImageClick = (value, setFieldValue) => {
    setFieldValue("homeLevel", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleSquareFootImgClick = (value, setFieldValue) => {
    setFieldValue("squareFootage", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)
      }`,
    borderRadius: 8,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  return (
    <Formik
      initialValues={{
        zipCode: formData.zipCode
          ? formData.zipCode
          : localStorage.getItem("zipCode") || '',
        homeLevel: "",
        squareFootage: "",
      }}
      validationSchema={ValidationSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={async (values) => {
        const cookies = await PostService.getUTMAttributes();
        const additionalInformation = {
          guid: cookies.guid,
          utm_campaign: cookies.utm_campaign ? cookies.utm_campaign.toUpperCase() : '',
        };
        const data = { ...formData, ...values, ...additionalInformation };
        setFormData(data);

        if (userJourneyTracking) {
          let stepNumber = 1;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 2;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.assessment event to DataLayer.
        DataLayerService.formAssessmentEvent(data);

        setActiveStepIndex(activeStepIndex + 1);

        // Scroll to the Top.
        scrollToTop(formRef);

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, errors, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              ref={formRef}
              className="flex flex-col justify-center items-start step1"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
              <h3 className="fw-normal mb-3">
                Your free, no-obligation estimate is good for an entire year.
              </h3>
              <ZipCodeInput
                fieldName={'zipCode'}
                style={{
                  display: formData.zipCode ? "none" : "inline"
                }}
                label= {formData.zipCode ? false : true }
              />

              <FormControl component="fieldset">
                <label className="small mb-3">How many levels does your home have? </label>
                <Field name="homeLevel">
                  {() => (
                    <LeafBox>
                      <LHOneStoryIcon handleClick={() =>
                        handleHomeSizeImageClick(
                          "oneStory",
                          setFieldValue
                        )
                      }></LHOneStoryIcon>

                      <LHTwoStoryIcon
                        handleClick={() =>
                          handleHomeSizeImageClick(
                            "twoStory",
                            setFieldValue
                          )
                        }
                      >
                      </LHTwoStoryIcon>
                      <LHThreeStoryIcon
                        handleClick={() =>
                          handleHomeSizeImageClick(
                            "threeStory",
                            setFieldValue
                          )
                        }
                      >
                      </LHThreeStoryIcon>
                    </LeafBox>
                  )}
                </Field>
              </FormControl>
              <LeafBox>
                <label className="small mb-3">How many square feet is it?</label>
                <Field name="squareFootage">
                  {() => (
                    <LeafBox>
                      <LHSmallIcon
                        handleClick={() =>
                          handleSquareFootImgClick(
                            "1999",
                            setFieldValue
                          )
                        }
                      >
                      </LHSmallIcon>
                      <LHMediumIcon
                        handleClick={() =>
                          handleSquareFootImgClick(
                            "2000",
                            setFieldValue
                          )
                        }
                      >
                      </LHMediumIcon>
                      <LHLargeIcon
                        handleClick={() =>
                          handleSquareFootImgClick(
                            "3000",
                            setFieldValue
                          )
                        }
                      >
                      </LHLargeIcon>
                    </LeafBox>
                  )}
                </Field>
              </LeafBox>
              <Divider className="divider" />
              <LeafBox>
                <LeafButton buttonClass='rounded-3 btn-primary w-100 fw-semibold' type="submit" handleClick={buttonSubmit} style={{
                  width: "100%"
                }}>
                  Next
                </LeafButton>
                <ProgressBar customClass="" />
                <TermAndCondition />
              </LeafBox>
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default Step1;
