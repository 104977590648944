import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import * as yup from "yup";
import {
  Container,
  useTheme,
  Paper
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import PostService from "utility/postService";
import DataLayerService from "utility/dataLayerService";
import Validation from "utility/validation";
import LeafBox from "components/atoms/LeafBox";
import { useProduct } from "hooks";

// Import SVG Icons.
import { LHWSOnlineIcon, LHWSEventIcon, LHWSPrintAdIcon, LHWSPostcardIcon, LHWSRadioIcon, LHWSTVIcon, LHWSCTVIcon } from "components/atoms/Icons";
import { FormContextLHWSMultiStepEstimate } from "./LHWSMultiStepEstimate";
import styled from "@emotion/styled";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import SMSMarketingCampaignComponent from "components/atoms/SMSMarketingCampaign";
import TermAndConditionLHWS from "components/atoms/TermAndConditionLHWS";
import { LeafButton } from "components/atoms/LeafButtons";
import { useCountry, useReCaptchaScore, useVersion } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";
import ProgressBarWithPercentage from 'components/atoms/ProgressBarWithPercentage';
import ReCAPTCHA from "react-google-recaptcha";
import { getactiveTestScriptAP, scrollToTop, modalScrollToTop } from '../../../../utility/functions';

const FinalStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const country = useCountry();
  const product = useProduct();
  const activeProspectTestScript = getactiveTestScriptAP();
  const selfScheduler = props.dataSet?.selfscheduler ? props.dataSet.selfscheduler : 'disable';
  const { setactiveLHWSStepIndex, formData, setFormData, marketoData, setRentHome } = useContext(FormContextLHWSMultiStepEstimate);
  const v2 = useVersion();
  const formRef = useRef(null);

  let phoneNumber = '';
  let errorMsg = "";
  let captchaScore = null;
  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  const ValidationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone Number is not valid.")
      .required("Phone Number is required."),
  });

  const buttonSubmit = (e) => {

    // Validation Error.
    const phoneValidation = Validation.PhoneDataLayerValidation(phoneNumber);

    if (Object.keys(phoneValidation).length !== 0) {
      DataLayerService.formFailEvent(form, location, phoneValidation);

      modalScrollToTop(props.modalComponent);
    }

    // Scroll to the Top.
    scrollToTop(formRef);
  }

  const handleImageClick = (value, setFieldValue) => {
    setFieldValue("adLevel", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleOnBlur = (event) => {
    phoneNumber = event.target.value;
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const recaptchaRef = useRef();
  const reCaptchaScoreConstant = useReCaptchaScore();

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  let phone = '';
  if (marketoData !== undefined && marketoData !== null) {
    phone = marketoData.phone;
  }

  return (
    <Formik
      initialValues={{
        phoneNumber: phone ? phone : '',
        adLevel: "",
        trustedCertURL: document.getElementsByName("xxTrustedFormCertUrl")[0]
        ? document.getElementsByName("xxTrustedFormCertUrl")[0].value
        : "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {

        // Validate if the user is not a Robot.
        let captchaValue = '';
        const RecaptchaVerification = await Validation.RecaptchaVerification();
        captchaScore = RecaptchaVerification.score;
        if (captchaScore < reCaptchaScoreConstant) {
          captchaValue = recaptchaRef.current.getValue();
        }
        else {
          captchaValue = RecaptchaVerification.token;
        }

        if (captchaValue || !reCaptchaShow) {
          const data = { ...formData, ...values };
          setFormData(data);

          let projectTime = '';
          switch (formData.projectTime) {
            case 'immediately':
              projectTime = 'Immediately';
              break;
            case '1-2-Weeks':
              projectTime = '1-2 Weeks';
              break;
            case 'flexible':
              projectTime = 'Flexible';
              break;
            default:
              projectTime = '';
              break;
          }

          const comments = "Project Time:" + projectTime + "\n" +
            "Type of Water:" + formData.typeofwater + "\n" +
            "Water Color:" + (formData.waterColor === 1 ? 'Yes' : 'No') + "\n" +
            "Financing: " + formData.financing + "\n" +
            "Senior:" + (formData.groupLevel === "senior" ? "Yes" : "No") + "\nVeteran:No\n" +
            "txtSMSMarketingConsent:" + (values.txtSMSMarketingConsent ? true : false) +
            "\nTrusted Form URL:" + values.trustedCertURL +
            "\nSurvey:" + values.adLevel +
            "\nHouse Type:" + (formData.houseType !== undefined ? formData.houseType : "") +
            "\nRecaptcha Score:" + captchaScore;

          let submittedValue = {
            'Offer': '0.10',
            'web_lead_source': "",
            'active_campaign': 'default',
            'comments': comments,
            'country': country,
            'emailAddress': formData.emailAddress,
            'firstName': formData.firstName,
            'fullName': formData.firstName + " " + formData.lastName,
            'lastName': formData.lastName,
            'phoneNumber': values.phoneNumber,
            'zipCode': formData.zipCode,
            'landing_page': window.location.pathname,
            'projectTime': formData.projectTime,
            'typeofwater': formData.typeofwater,
            'waterColor': (formData.waterColor === 1 ? 'Yes' : 'No'),
            'financing': formData.financing,
            'recaptcha_token': captchaValue,
            'recaptcha_score': captchaScore,
            'source': (formData.utm_campaign.substr(-3, 3) !== "-ND") ? values.adLevel : '',
            'QualifiedLead': false,
            'FirstName': formData.firstName,
            'LastName': formData.lastName,
            'Email': formData.emailAddress,
            'Phone': values.phoneNumber,
            'Zip': formData.zipCode,
            'WebEventDetail': '',
            'LeadSourceId': 1,
            'LeadSource': '41',
            'Notes': '',
            'product': product,
            'houseType' : formData.houseType || "",
            'surveyResults': values.adLevel,
          }

          let rentedHome = (formData.houseType === 'Rent' ? true: false);

          if (selfScheduler === 'enable' && !rentedHome) {
            submittedValue = {
              ...submittedValue,
              ...{'utm_content': 'ss-delay'}
            }
          }

          try {
            // Generate Marketo Lead.
            PostService.postData('create-lead', submittedValue);

          }  catch (error) {
            console.log(error);
            console.log("Error in sending data");
          }

          if (activeProspectTestScript === 'nosandbox') {

            const certificateInformation = {
              'certificateLink': values.trustedCertURL,
              'userEmail': formData.emailAddress,
              'userPhone': values.phoneNumber,
            }

            try {
              const response = await PostService.retainCertificate('retain-cert', certificateInformation);
              console.log(response);
            } catch (error) {
              console.log(error);
              console.log("Error in retaining the Certificate.");
            }
          }

          try {
            const response = await PostService.postData('lhws-estimate', submittedValue, true);
            if (response.success) {
              setRentHome(rentedHome);
              setactiveLHWSStepIndex('ThankYou');

              // Scroll to the Top.
              scrollToTop(formRef);

              // Passing lf.form.success event to DataLayer.
              DataLayerService.formSuccessEvent(form, 'estimate', location);

              // Passing lf.form.start event to DataLayer.
              DataLayerService.formStartEvent(form, location, data);

              localStorage.removeItem("zipCode");
            }
            else {
              errorMsg = "Sorry, there was an error during submission. Please try again.";
              console.log(response.msg);
              actions.setSubmitting(false);
            }
          } catch (error) {
            console.log(error);
            console.log("Error in sending data");
          }
        }
      }}
    >
      {({ handleSubmit, setFieldValue, values, touched, errors, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex flex-col justify-center items-start"
              onSubmit={handleSubmit}
              ref={formRef}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <h3 className="form-step-title text-2xl fw-700">
                What's the best phone number to reach you at?
              </h3>
              <div className="flex flex-col items-start mb-2">
                {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : ""}
                <PhoneNumberInput touched={touched} errors={errors} fieldName={'phoneNumber'} phoneNumberText='' />
              </div>

              { v2 ?
                <Divider className="divider" />
                :
                ''
              }

              <SMSMarketingCampaignComponent />

              <Divider className="divider" />

              <LeafBox>
                <label className="font-medium text-green-600">
                  How Did You Hear About Us?{" "}
                  { v2 ? '' : <span className="optional">(optional)</span> }
                </label>
                <Field name="adLevel">
                  {() => (
                      <LeafBox className="adLevel"
                      style={{
                        display: "flex",
                        alignItems: "start",
                        flexWrap: "wrap"
                      }}>
                      <LHWSOnlineIcon handleClick={() =>
                        handleImageClick("41", setFieldValue)
                      } />
                      <LHWSTVIcon handleClick={() =>
                        handleImageClick("81", setFieldValue)
                      } />
                      <LHWSCTVIcon handleClick={() =>
                        handleImageClick("41TCTV", setFieldValue)
                      } />
                      <LHWSEventIcon handleClick={() =>
                        handleImageClick("99w", setFieldValue)
                      } />
                      <LHWSPrintAdIcon handleClick={() =>
                        handleImageClick("88w", setFieldValue)
                      } />
                      <LHWSPostcardIcon handleClick={() =>
                        handleImageClick("10w", setFieldValue)
                      } />
                      <LHWSRadioIcon handleClick={() =>
                        handleImageClick("91", setFieldValue)
                      } />
                    </LeafBox>
                  )}
                </Field>
              </LeafBox>

              <Field type="hidden" name="trustedCertURL" />
              {reCaptchaShow && (
                <div className="recaptcha">
                  {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                    <>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="normal"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                      />
                      <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                    </>
                  )}
                </div>
              )}
              <LeafBox style={{
                marginTop: 2,
                marginBottom: 3,
              }}>
                <LeafButton buttonType={v2 ? "primary" : "secondary"} type="submit" onClick={buttonSubmit} style={{
                  width: "100%"
                }}
                isSubmitting={isSubmitting}>
                  {isSubmitting ? ("Submitting") : ("Submit for Quote")}
                </LeafButton>
              </LeafBox>
              { v2 ?
                <ProgressBarWithPercentage />
                :
                <ProgressBar customClass="" />
              }
              <TermAndConditionLHWS buttonText={"Submit for Quote"} />

            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default FinalStep;
