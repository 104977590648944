import { useContext } from "react";
import { FormContext } from "App";

/**
 * Consolidate country / brand into product. Useful for determining site link,
 * telephone link etc.
 *
 * @returns string
 */
export const useProduct = () => {
  const { country, brand } = useContext(FormContext);
  const product = country || brand || 'us';
  return product;
}

/**
 * Returns country.
 *
 * @returns string country
 */
export const useCountry = () => {
  const { country } = useContext(FormContext);
  return country;
}

/**
 * Returns Version.
 *
 * @returns boolean version
 */
export const useVersion = () => {
  const { v2 } = useContext(FormContext);
  return v2;
}

/**
 * Returns brand.
 *
 * @returns string brand
 */
export const useBrand = () => {
  const { brand } = useContext(FormContext);
  return brand;
}

/**
 * Returns Leafhome Version.
 *
 * @returns boolean version
 */
export const useVersionLeafhome = () => {
  const { vLeafhome } = useContext(FormContext);
  return vLeafhome;
}

/**
 * Returns reCaptchaScore.
 *
 * @returns string reCaptchaScore
 */
export const useReCaptchaScore = () => {
  const reCaptchaScore = ( process.env.REACT_APP_PANTHEON_ENVIRONMENT === 'live' ? process.env.REACT_APP_RECAPTCHA_SCORE : process.env.REACT_APP_NON_PROD_RECAPTCHA_SCORE );
  return reCaptchaScore;
}

/**
 * Returns component.
 *
 * @returns string componentName
 */
export const useComponentName = (component) => {
  let componentName = '';

  switch (component) {
    case 'MultiStepper':
      componentName = 'Multi Stepper Form';
      break;

    case 'SelfScheduler':
      componentName = 'Multi Stepper Form with Self Scheduler';
      break;

    case 'ShortLeadForm':
      componentName = 'Short Lead Form';
      break;

    case 'ZipChecker':
      componentName = 'Zip Checker Form';
      break;

    case 'SingleStepper':
      componentName = 'Single Stepper Form';
      break;

    case 'LHSSMultiStepEstimate':
      componentName = 'LHSS Multi Stepper Form';
      break;

    case 'LHWSMultiStepEstimate':
      componentName = 'LHWS Multi Stepper Form';
      break;

    case 'SingleStepperLHWS':
      componentName = 'LHWS Single Stepper Form';
      break;

    case 'ModalForm':
      componentName = 'Sticky button';
      break;

    case 'LeafHomeZipChecker':
      componentName = 'Leafhome Zip Checker Form';
      break;

    case 'LeafHomeMultiStepper':
      componentName = 'Leafhome Multi Stepper Form';
      break;

    case 'LeafHomeSingleStepper':
      componentName = 'Leafhome Single Stepper Form';
      break;

    case 'LeafHomeLHSSMultiStepper':
      componentName = 'Leafhome Stairlifts Multi Stepper Form';
      break;

    case 'LeafHomeLHWSMultiStepper':
      componentName = 'Leafhome Water Solutions Multi Stepper Form';
      break;

    case 'LeafHomeLHWSSingleStepper':
      componentName = 'Leafhome Water solutions Single Stepper Form';
      break;

    case 'LeafHomeSelfScheduler':
      componentName = 'Leafhome Multi Stepper Form with Self Scheduler';
      break;

    case 'LeafHomeShortLeadForm':
      componentName = 'Leafhome Short Lead Form';
      break;

    default:
      break;
  }
  return componentName;
}

/**
 * Returns Zipcode Placeholder Text.
 *
 * @returns string zipcodePlaceholder
 */
export const useZipcodePlaceholder = () => {
  const { zipcodePlaceholder } = useContext(FormContext);
  return zipcodePlaceholder;
}
